<template>
<CRow>
  <CCol col="12" xl="12">
       <CCard class="cards">
      <CCardHeader>
        <CCol col="6" class="styleHeader">{{ $t('message.showProduct')}} </CCol>
      </CCardHeader>
      <CCardBody>
        <div class="row">
            <div class="col-md-6">
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $t('message.productName')}} </th>
                    <td v-if="$i18n.locale == 'English'">{{ product.name }}</td>
                    <td v-if="$i18n.locale == 'العربية'">{{ product.name_ar }}</td>
                  </tr>
                   <tr>
                     <th>{{ $t('message.description')}} </th>
                     <td v-if="$i18n.locale == 'English'">{{ product.description }}</td>
                    <td v-if="$i18n.locale == 'العربية'">{{ product.description_ar }}</td>
                  </tr>
                   <tr>
                    <th> {{ $t('message.productPrice')}}</th>
                    <td>{{ product.price}}</td>
                  </tr>
                   <tr>
                    <th> {{ $t('message.productQuantityStock')}}</th>
                    <td>{{ product.stock }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.category')}}</th>
                    <td>{{ product.category.name }}</td>
                  </tr>

                </table>
            </div>
        </div>
         <div class="row">
            <div class="col-md-10 m-auto">
              <div class="title my-3">
                <h1 class="text-center">{{ $t('message.productSupplier')}}</h1>
              </div>
              <table class="table table-bordered">
                  <tr>
                    <th>اسم المُورد  </th>
                      <th> البريد الإلكتروني  </th>
                       <th> الهاتف  </th>
                  </tr>
                   <tr >
                    <td>{{ product.supplier.name }}</td>
                    <td>{{ product.supplier.email }}</td>
                    <td>{{ product.supplier.phone }}</td>
                  </tr>
                </table>
            </div>
        </div>
        <CButton color="btn btn-primary" @click="goBack">{{ $t('message.back')}}</CButton>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
export default {
  name: 'ShowProduct',
  data () {
    return {
      product: [],
      suppliers: []
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}products/${this.$route.params.id}`)
      .then((response) => {
        this.product = response.data.data.product
      })
    // this.$http
    //   .get(`${this.$hostUrl}products/${this.$route.params.id}/suppliers`)
    //   .then((response) => {
    //     this.suppliers = response.data.data
    //   })
  },
  methods: {
    goBack () {
      this.servicesOpened ? this.$router.go(-1) : this.$router.push({ path: '/products' })
    }
  }
}
</script>
<style scoped>
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
  .title
  {
    background-color: #9daec5;
    color: #f5f3f3;
    border-radius: 20%;
    padding: 7px;
  }
</style>
